/** @format */

import Vue from 'vue'
import VueCompositionApi, { ref, reactive } from '@vue/composition-api'
Vue.use(VueCompositionApi)
// import Store from '@/store'
// import numeral from 'numeral'

const rows = ref([])
const showSearchbox = ref()
const isComponentModalActive = ref(false)
const keyRefreshSearchUnpaidInvoices = ref(0)
const addInvoiceFeeToPayment = (payload) => {
	let feeToPay = 0
	if (payload.invoice_type === 'Inland') {
		feeToPay = payload.is_fee_addable
			? payload.paper_invoice_fee + payload.paper_invoice_fee_vat
			: 0
	}
	return feeToPay
}
// ---------------------------------------------------------------------------------------------------
const invoiceToPay = (payload) => {
	let invoiceTotal =
		Math.round(
			(payload.invoice_totals_aggregate.aggregate.sum.line_price_total_credit +
				payload.invoice_totals_aggregate.aggregate.sum.vat_credit +
				addInvoiceFeeToPayment(payload)) /
				100,
		) * 100
	return invoiceTotal
}
const invoiceToPayExchange = (payload) => {
	let invoiceTotalExc =
		Math.round(
			(payload.invoice_totals_aggregate.aggregate.sum
				.line_price_total_credit_exchange +
				payload.invoice_totals_aggregate.aggregate.sum.vat_credit_exchange +
				addInvoiceFeeToPayment(payload)) /
				100,
		) * 100
	return invoiceTotalExc
}
// -------------------------------------------------------------------------------------------------------
const invoicePayment = (payload) => {
	const salesInvoicePayment =
		payload.invoice_payments_aggregate.aggregate.sum.payment_credit || 0
	return salesInvoicePayment
}
const invoicePaymentExchange = (payload) => {
	const salesInvoicePayment =
		payload.invoice_payments_aggregate.aggregate.sum
			.payment_credit_in_exchange || 0
	return salesInvoicePayment
}
// --------------------------------------------------------------------------------------------
const invoiceBalance = (payload) => {
	const toPay =
		Math.round(
			(payload.invoice_totals_aggregate.aggregate.sum.line_price_total_credit +
				payload.invoice_totals_aggregate.aggregate.sum.vat_credit +
				addInvoiceFeeToPayment(payload)) /
				100,
		) * 100
	const payment =
		payload.invoice_payments_aggregate.aggregate.sum.payment_credit || 0
	const saleInvoiceBalance = toPay - payment
	return saleInvoiceBalance
}
const invoiceBalanceExchange = (payload) => {
	const toPay =
		Math.round(
			(payload.invoice_totals_aggregate.aggregate.sum
				.line_price_total_credit_exchange +
				payload.invoice_totals_aggregate.aggregate.sum.vat_credit_exchange +
				addInvoiceFeeToPayment(payload)) /
				100,
		) * 100
	const payment =
		payload.invoice_payments_aggregate.aggregate.sum
			.payment_credit_in_exchange || 0
	const saleInvoiceBalanceExc = toPay - payment
	return saleInvoiceBalanceExc
}
const collectionDate = ref(new Date())
const collectionType = ref('collect bank')
const collectionDateFormatted = ref(null)
const isInvoicePaid = ref('false')
const selectedInvoiceState = reactive({
	invoiceOurCompany: '',
	customerId: '',
	company: '',
	invoiceNumber: '',
	// transactionType: '',
	invoiceType: '',
	isCredit: false,
	// isInExchange: false,
	invoiceCurrency: '',
	invoiceExcRate: 1,
	// -----------------------------
	invoiceTotal: '',
	paymentTotal: '',
	balanceTotal: '',
	// -----------------------------
	invoiceTotalInExchange: '',
	paymentTotalInExchange: '',
	balanceTotalInExchange: '',
	// -----------------------------
	balanceTotalDb: '',
	balanceTotalInExchangeDb: '',
	// -----------------------------
	showAddIncomingTransactionFormFrame: false,
})

const isModalVisible = ref(false)
const keySearchUnpaidInvoices = ref(0)

const modelTransaction = reactive({
	transactionType: '',
	invoiceType: '',
	reportType: '',
	// -----------------------------
	paymentType: '',
	paymentCurrency: '',
	paymentExchangeRate: '',
	paymentDate: '',
	paymentCreditAccount: '',
	paymentDebitAccount: '',
})

const transactionFormState = reactive({
	paymentCredit: 0,
	paymentCreditInExchange: 0,
	searchType: '',
	keySearch: 1,
	isSearchUnpaidInvoicesShown: true,
})

export default function useTransactionsNewState() {
	return {
		rows,
		showSearchbox,
		isComponentModalActive,
		collectionType,
		invoiceToPay,
		invoiceToPayExchange,
		invoiceBalance,
		invoiceBalanceExchange,
		invoicePayment,
		invoicePaymentExchange,
		selectedInvoiceState,
		isModalVisible,
		modelTransaction,
		transactionFormState,
		keyRefreshSearchUnpaidInvoices,
		isInvoicePaid,
		// ***********************
		collectionDate,
		collectionDateFormatted,
		keySearchUnpaidInvoices,
		// calculatePaymentFields,
		// checkBalancePayment,
		// checkBalancePaymentExchange,
	}
}
