<template>
	<div>
		<div v-if="isComponentModalActive">
			<div>Is invoice fully paid: {{ isInvoicePaid }}</div>
			<div>
				With or without invoice:
				{{ showSearchbox === 'invoice' ? 'With invoice' : 'Without invoice' }}
			</div>
			<div v-for="(value, key) in transactionObject" :key="key">
				{{ key }}: {{ value }}
			</div>
			<div class="margin-bottom-30; margin-top-30">
				<span>
					<b-button
						class="width-250"
						style="background-color: hsl(141, 71%, 48%); color: white"
						:disabled="isConfirmButtonDisabled"
						@click="addTransaction()"
						>Confirm collection
					</b-button>
				</span>
				<span class="margin-left-20">
					<b-button
						class="width-250"
						type="is-danger"
						outlined
						@click="isComponentModalActive = false"
						>Cancel & return to edit
					</b-button>
				</span>
			</div>
		</div>

		<div v-if="!isComponentModalActive">
			<form id="formTransaction" style="width: 600px; margin-bottom: 30px">
				<div>
					<b-field class="margin-top-20" label="Select payment date">
						<b-datepicker
							v-model="collectionDate"
							:show-week-number="showWeekNumber"
							:locale="locale"
							placeholder="Click to select..."
							icon="calendar-today"
							trap-focus
							:first-day-of-week="firstDayOfTheWeek"
							:max-date="today"
						>
						</b-datepicker>
					</b-field>
				</div>

				<div
					v-if="showSearchbox === 'customer'"
					style="width: 600px; margin-top: 15px; margin-bottom: 15px"
				>
					<b-field style="color: white" label="Collection Currency">
						<b-select
							v-model="modelTransaction.paymentCurrency"
							placeholder="Select an currency"
							expanded
						>
							<option
								v-for="option in data3"
								:value="option.currency"
								:key="option.id"
							>
								{{ option.currency }}
							</option>
						</b-select>
					</b-field>
				</div>
				<div style="margin-bottom: 15px" v-if="showExchangeRate">
					<b-field :label="label1">
						<b-input
							v-model="field1InputValue"
							@input.native="field1OnInput"
							placeholder="Enter a exchange rate"
							:disabled="isInvoiceExchangeKr"
						></b-input>
					</b-field>
					<b-field>
						<b-button
							style="background-color: rgb(52, 136, 206); color: white"
							expanded
							@click="getExchangeRate()"
						>
							<span style="font-weight: 900"
								>Get the exchange rate on the payment day</span
							>
						</b-button>
					</b-field>
				</div>
				<div style="margin-bottom: 15px">
					<b-field :label="label2">
						<b-input
							v-model="field2InputValue"
							@input.native="field2OnInput"
							placeholder="Enter the amount of collection"
						></b-input>
					</b-field>
				</div>

				<div style="width: 600px">
					<b-field style="color: white" label="Collection Credit Account">
						<b-select
							v-model="modelTransaction.paymentCreditAccount"
							placeholder="Select an account"
							expanded
						>
							<option
								v-for="option in data1"
								:value="option.account"
								:key="option.id"
							>
								{{ option.account }}
							</option>
						</b-select>
					</b-field>
				</div>
				<div style="width: 600px; margin-top: 15px">
					<b-field style="color: white" label="Collection Debit Account">
						<b-select
							v-model="modelTransaction.paymentDebitAccount"
							placeholder="Select an account"
							expanded
						>
							<option
								v-for="option in data2"
								:value="option.account"
								:key="option.id"
							>
								{{ option.account }}
							</option>
						</b-select>
					</b-field>
				</div>
			</form>
			<div class="margin-bottom-30">
				<span>
					<b-button
						class="width-250"
						style="background-color: rgb(52, 136, 206); color: white"
						:disabled="isButtonDisabled"
						@click="onCreateTransaction"
						>Create collection transaction
					</b-button>
				</span>
				<span class="margin-left-20">
					<b-button
						class="width-250"
						type="is-danger"
						outlined
						@click="onCancel"
						>Cancel
					</b-button>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import Store from '@/store'
import { useMutation } from '@vue/apollo-composable'
import {
	ref,
	onMounted,
	onUnmounted,
	computed,
	watch,
	watchEffect,
} from '@vue/composition-api'
import useTransactionsNewState from '../store/useTransactionsNewState'
import useIncomingTransactionFunctions from '../scripts/useIncomingTransactionFunctions'
import AddIncomingTransactionMutation from '../graphql/AddIncomingTransactionMutation.graphql'
import AddIncomingTransactionWithoutInvoiceMutation from '../graphql/AddIncomingTransactionWithoutInvoiceMutation.graphql'
// *****************************************************************************
import useExchangeRate from '@/_srcv2/components/_shared/exchange-api/useExchangeRates.js'

export default {
	name: 'IncomingTransactionForm',
	setup() {
		const {
			useFormatNumber,
			formatNumber,
			basPlan,
			formatDateToYYYYMMDD,
			formatNumberWithSeparators,
		} = useIncomingTransactionFunctions()
		const {
			modelTransaction,
			transactionFormState,
			selectedInvoiceState,
			rows,
			showSearchbox,
			isModalVisible,
			isComponentModalActive,
			collectionDate,
			collectionDateFormatted,
			keyRefreshSearchUnpaidInvoices,
			isInvoicePaid,
		} = useTransactionsNewState()

		const { getExchangeRatesCross } = useExchangeRate()

		// ? ----------------------------------------------------------
		const today = ref(new Date())

		watch(collectionDate, (newValue) => {
			console.log('====> collectionDate newValue', newValue)
			collectionDateFormatted.value = formatDateToYYYYMMDD(newValue)
			console.log(
				'====> watch collectionDateFormatted',
				collectionDateFormatted.value,
			)
		})
		const showWeekNumber = ref(false)
		const firstDayOfTheWeek = ref(1)
		const locale = 'sv-SE'

		const label1 = computed(() => {
			return `Collection Exchange Rate: 1 ${modelTransaction.paymentCurrency} = ........ kr.`
		})
		const label2 = computed(() => {
			return `Collection in Exchange ${modelTransaction.paymentCurrency}`
		})
		const data1 = computed(() => {
			return basPlan.value
		})
		const data2 = computed(() => {
			return basPlan.value
		})
		const data3 = ref([
			{ id: 1, currency: 'kr.' },
			{ id: 2, currency: '€' },
			{ id: 3, currency: '$' },
			{ id: 4, currency: 'NOK' },
			{ id: 5, currency: 'DKK' },
			{ id: 6, currency: 'TRY' },
			{ id: 7, currency: 'PLN' },
		])
		// todo --------------------------------------------------------------------
		// ? Use the composable for Field 1 (space for thousands, comma for decimals, 4 decimal places)
		const {
			inputValue: field1InputValue,
			unformattedValue: field1UnformattedValue,
			onInput: field1OnInput,
		} = useFormatNumber(' ', ',', 4)

		// ? Use the composable for Field 2 (space for thousands, comma for decimals, 2 decimal places)
		const {
			inputValue: field2InputValue,
			unformattedValue: field2UnformattedValue,
			onInput: field2OnInput,
		} = useFormatNumber(
			' ',
			',',
			2,
			selectedInvoiceState.balanceTotalInExchange / 100,
		)
		// todo --------------------------------------------------------------------

		// todo Set default state form model
		const setInitialState = () => {
			modelTransaction.paymentExchangeRate = ''
			modelTransaction.paymentDate = Store.getters.getTransactionDate
			modelTransaction.customerID = selectedInvoiceState.customerId
			modelTransaction.invoiceNumber = selectedInvoiceState.invoiceNumber
			modelTransaction.ourCompany = selectedInvoiceState.invoiceOurCompany
			modelTransaction.paymentCurrency = selectedInvoiceState.invoiceCurrency
			isButtonActivated.value = false
		}
		const checkIsFieldEmpty = (fieldValue) => {
			return (
				fieldValue === '' || fieldValue === null || fieldValue === undefined
			)
		}
		const isButtonActivated = ref(false)
		const isButtonDisabled = computed(() => {
			if (isButtonActivated.value) {
				return checkIsFieldEmpty(field2InputValue.value)
			} else if (selectedInvoiceState.invoiceCurrency !== 'kr.') {
				return (
					checkIsFieldEmpty(field1InputValue.value) ||
					checkIsFieldEmpty(field2InputValue.value)
				)
			} else {
				return checkIsFieldEmpty(field2UnformattedValue.value)
			}
		})
		// todo --------------------------------------------------------------------
		const transactionObject = ref({
			invoice_number: selectedInvoiceState.invoiceNumber,
			payment_type: modelTransaction.paymentType,
			payment_date: '',
			payment_currency: selectedInvoiceState.invoiceCurrency,
			payment_exchange_rate: 1,
			payment_debit: 0,
			payment_debit_in_exchange: 0,
			payment_credit: 0,
			payment_credit_in_exchange: 0,
			payment_exchange_difference: 0,
			payment_credit_account: modelTransaction.paymentCreditAccount,
			payment_debit_account: modelTransaction.paymentDebitAccount,
			our_company: selectedInvoiceState.invoiceOurCompany,
			customer_id: selectedInvoiceState.customerId,
			report_type: modelTransaction.reportType,
		})
		// ? --------------------------------------------------------------------
		const getPaymentExchangeRate = () => {
			if (!isInvoiceExchangeKr.value) {
				console.log(
					' not kr. selectedInvoiceState.invoiceCurrency',
					selectedInvoiceState.invoiceCurrency,
				)
				transactionObject.value.payment_exchange_rate =
					field1UnformattedValue.value
			} else {
				console.log(
					'kr. selectedInvoiceState.invoiceCurrency',
					selectedInvoiceState.invoiceCurrency,
				)
				transactionObject.value.payment_exchange_rate = 1
				console.log(
					'else payment_exchange_rate',
					transactionObject.value.payment_exchange_rate,
				)
			}
		}
		const getPaymentCreditExchange = () => {
			transactionObject.value.payment_credit_in_exchange = parseInt(
				parseFloat(field2UnformattedValue.value) * 100,
			)
		}
		const getPaymentCredit = () => {
			if (isInvoiceExchangeKr.value) {
				transactionObject.value.payment_credit =
					transactionObject.value.payment_credit_in_exchange
			} else {
				let paymentInExchange = parseInt(
					parseFloat(field2UnformattedValue.value) * 100,
				)
				let excRate = field1UnformattedValue.value * 10000
				let paymentCreditSEK = paymentInExchange * excRate
				transactionObject.value.payment_credit = parseInt(
					parseFloat(paymentCreditSEK) / 10000,
				)
			}
		}
		const paymentCreditInvoice = ref()
		const getPaymentCreditInvoiceExchange = () => {
			let paymentInExchange = parseInt(
				parseFloat(field2UnformattedValue.value) * 100,
			)
			let excRate = selectedInvoiceState.invoiceExcRate
			let paymentCreditInvoiceRaw = Math.round(paymentInExchange / excRate)
			paymentCreditInvoice.value = parseInt(parseFloat(paymentCreditInvoiceRaw))
		}
		const getPaymentExchangeDifference = () => {
			transactionObject.value.payment_exchange_difference =
				transactionObject.value.payment_credit - paymentCreditInvoice.value
		}
		const condition = true
		const setTransactionObject = () => {
			return new Promise((resolve, reject) => {
				if (condition) {
					let balanceInvoice = selectedInvoiceState.balanceTotalInExchangeDb
					console.log('setTransactionObject balanceInvoice', balanceInvoice)
					let collection = field2UnformattedValue.value
					console.log('setTransactionObject collection', collection)
					const newBalance = balanceInvoice - collection
					console.log('setTransactionObject newBalance', newBalance)
					isInvoicePaid.value = newBalance === 0 ? 'true' : 'false'
					console.log('setTransactionObject isInvoicePaid', isInvoicePaid.value)
					getPaymentExchangeRate()
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// * -----------------------------------------------------------------------------
		const addTransaction = () => {
			let expression = showSearchbox.value
			console.log('addTransaction is fired', expression)
			switch (expression) {
				case 'customer':
					addPaymentWithoutInvoice()
					break
				case 'invoice':
					addPayment()
					break
				default:
					addPayment()
			}
		}
		// todo ---------------------------------------------------------------------
		function removeObjectByKey(key, value, array) {
			console.log('removeObjectByKey is fired')
			const indexToRemove = array.findIndex((obj) => obj[key] === value)
			if (indexToRemove !== -1) {
				array.splice(indexToRemove, 1) // ? Remove 1 element at the found index
				return true // ? Indicate successful removal
			} else {
				return false // ? Indicate that the object was not found
			}
		}
		const removePaidInvoiceFromTable = () => {
			console.log('removePaidInvoiceFromTable is fired')
			const keyToRemove = ref('invoiceNum')
			const valueToRemove = computed(() => selectedInvoiceState.invoiceNumber)
			// * Remove paid invoice from list *******************************************************
			removeObjectByKey(keyToRemove.value, valueToRemove.value, rows.value)
			keyRefreshSearchUnpaidInvoices.value += 1
		}
		// todo --------------------------------------------------------------------
		const isConfirmButtonDisabled = ref(false)
		const onCreateTransaction = () => {
			isConfirmButtonDisabled.value = true
			setTimeout(() => {
				isConfirmButtonDisabled.value = false
			}, 1000)
			setTransactionObject()
				.then(() => {
					transactionObject.value.payment_date = collectionDateFormatted.value
					getPaymentCreditExchange()
				})
				.then(() => {
					getPaymentCredit()
				})
				.then(() => {
					getPaymentCreditInvoiceExchange()
				})
				.then(() => {
					getPaymentExchangeDifference()
				})
				.then(() => {
					isComponentModalActive.value = true
				})
		}
		const onCancel = () => {
			isModalVisible.value = false
			isComponentModalActive.value = false
		}
		// * --------------------------------------------------------------------------------
		const {
			mutate: addPayment,
			onDone: onDoneWithInvoice,
			onError: onErrorWithInvoice,
		} = useMutation(AddIncomingTransactionMutation, () => ({
			variables: {
				payment: transactionObject.value,
				our_company: modelTransaction.ourCompany,
				invoice_number: modelTransaction.invoiceNumber,
				is_invoice_paid: isInvoicePaid.value,
			},
		}))
		// Create alarm for mutations
		onDoneWithInvoice((result) => {
			console.log('onDoneWithInvoice', result)
			let paidInvoiceNumber =
				result.data.insert_payments.returning[0].invoice_number
			let isInvoiceTotallyPaid =
				result.data.update_document_transactions.returning[0]?.is_invoice_paid
			let collectedAmount =
				result.data.insert_payments.returning[0].payment_credit_in_exchange /
				100
			let transactionCurrency =
				result.data.insert_payments.returning[0].payment_currency
			let paymentAmountForPopup = formatNumber(
				collectedAmount,
				' ',
				',',
				2,
				transactionCurrency,
			)
			let popUpMsg = `
      The transaction is saved
      Invoice Number: ${paidInvoiceNumber}
      Collected Amount: ${paymentAmountForPopup}
      Is Totally Paid: ${isInvoiceTotallyPaid}
      `
			alert(popUpMsg)
			console.log(result)
			removePaidInvoiceFromTable()
			// * Close Modal ***************************************
			isModalVisible.value = false
			isComponentModalActive.value = false
			transactionFormState.isSearchUnpaidInvoicesShown = false
			setTimeout(() => {
				transactionFormState.isSearchUnpaidInvoicesShown = true
				transactionFormState.keySearch += 1
			}, 3000)
		})
		onErrorWithInvoice((error) => {
			const errMessage = `Something went wrong. Item can not be saved check the data you have entered!!!  -  ${error}`
			alert('Error', errMessage)
		})
		// * --------------------------------------------------------------------------------
		const {
			mutate: addPaymentWithoutInvoice,
			onDone: onDoneWithoutInvoice,
			onError: onErrorWithoutInvoice,
		} = useMutation(AddIncomingTransactionWithoutInvoiceMutation, () => ({
			variables: {
				payment: transactionObject.value,
			},
		}))
		// Create alarm for mutations
		onDoneWithoutInvoice((result) => {
			console.warn('onDoneWithoutInvoice', result)
			let paidInvoiceNumber =
				result.data.insert_payments.returning[0].invoice_number
			let collectedAmount =
				result.data.insert_payments.returning[0].payment_credit_in_exchange /
				100
			let transactionCurrency =
				result.data.insert_payments.returning[0].payment_currency
			let paymentAmountForPopup = formatNumber(
				collectedAmount,
				' ',
				',',
				2,
				transactionCurrency,
			)
			let popUpMsg = `
      The transaction is saved
      Invoice Number: ${paidInvoiceNumber}
      Payment Amount: ${paymentAmountForPopup}
      `
			alert(popUpMsg)
			// * Close Modal ***************************************
			isModalVisible.value = false
			isComponentModalActive.value = false
			setTimeout(() => {
				transactionFormState.isSearchUnpaidInvoicesShown = true
				transactionFormState.keySearch += 1
			}, 1500)
		})
		onErrorWithoutInvoice(() => {
			const errMessage =
				'Something went wrong. Item can not be saved check the data you have entered!!!'
			alert('Error', errMessage)
		})
		// **************************************************************************
		const isInvoiceExchangeKr = ref()
		const showExchangeRate = ref(false)
		watchEffect(() => {
			console.log(
				'watch modelTransaction.paymentCurrency',
				modelTransaction.paymentCurrency,
			)
			if (modelTransaction.paymentCurrency === 'kr.') {
				isInvoiceExchangeKr.value = true
				showExchangeRate.value = false
			} else {
				isInvoiceExchangeKr.value = false
				showExchangeRate.value = true
			}
		})

		const getExchangeRate = () => {
			const currency = computed(() => {
				if (modelTransaction.paymentCurrency === 'kr.') {
					return 'SEK'
				} else if (modelTransaction.paymentCurrency === '€') {
					return 'EUR'
				} else if (modelTransaction.paymentCurrency === '$') {
					return 'USD'
				} else {
					return modelTransaction.paymentCurrency
				}
			})
			const formatRate = (rate) => {
				return rate.toFixed(4).replace('.', ',')
			}
			getExchangeRatesCross(currency.value)
				.then((rate) => {
					console.log('Exchange Rate (SEK):', rate)
					modelTransaction.paymentExchangeRate = rate
					field1InputValue.value = formatRate(rate)
				})
				.then(() => {
					console.log('Exchange Rate Input:', field1InputValue.value)
					console.log(
						'Exchange Rate unformatted:',
						field1UnformattedValue.value,
					)
					isButtonActivated.value = true
				})
				.catch((error) => console.error('Failed to fetch rate:', error))
		}
		onMounted(() => {
			// todo set form default state
			setInitialState()
			if (selectedInvoiceState.invoiceCurrency === 'kr.') {
				isInvoiceExchangeKr.value = true
			} else {
				isInvoiceExchangeKr.value = false
			}
			// ? ------------------------------------------------
			field2InputValue.value = formatNumberWithSeparators(
				selectedInvoiceState.balanceTotalInExchangeDb,
			)
			field2UnformattedValue.value =
				selectedInvoiceState.balanceTotalInExchangeDb
			modelTransaction.paymentCurrency = selectedInvoiceState.invoiceCurrency
		})
		onUnmounted(() => {
			isComponentModalActive.value = false
			selectedInvoiceState.invoiceOurCompany = ''
			selectedInvoiceState.customerId = ''
			selectedInvoiceState.company = '' // ? customer
			selectedInvoiceState.invoiceNumber = ''
			selectedInvoiceState.invoiceType = ''
			selectedInvoiceState.isCredit = false
			selectedInvoiceState.invoiceCurrency = ''
			selectedInvoiceState.invoiceExcRate = 1
			selectedInvoiceState.invoiceTotal = 0
			selectedInvoiceState.paymentTotal = 0
			selectedInvoiceState.balanceTotal = 0
			selectedInvoiceState.invoiceTotalInExchange = 0
			selectedInvoiceState.paymentTotalInExchange = 0
			selectedInvoiceState.balanceTotalInExchange = 0
			selectedInvoiceState.balanceTotalDb = ''
			selectedInvoiceState.balanceTotalInExchangeDb = ''
			// selectedInvoiceState.showAddIncomingTransactionFormFrame = false
			// **-------------------------------------------------------------
			// modelTransaction.transactionType = ''
			modelTransaction.invoiceType = ''
			modelTransaction.reportType = ''
			modelTransaction.paymentType = ''
			modelTransaction.paymentCurrency = ''
			modelTransaction.paymentExchangeRate = ''
			modelTransaction.paymentDate = ''
			modelTransaction.paymentCreditAccount = ''
			modelTransaction.paymentDebitAccount = ''
			// **-------------------------------------------------------------
			transactionFormState.paymentCredit = 0
			transactionFormState.paymentCreditInExchange = 0
			transactionFormState.searchType = ''
			transactionFormState.keySearch = 1
		})
		return {
			label1,
			label2,
			showSearchbox,
			//*********************
			firstDayOfTheWeek,
			showWeekNumber,
			locale,
			today,
			collectionDate,
			showExchangeRate,
			// --------------------------
			field1InputValue,
			field1UnformattedValue,
			field1OnInput,
			// Field 2
			field2InputValue,
			field2UnformattedValue,
			field2OnInput,
			isInvoicePaid,
			isButtonDisabled,
			// **********************
			data1,
			data2,
			data3,
			isInvoiceExchangeKr,
			modelTransaction,
			transactionFormState,
			transactionObject,
			onCreateTransaction,
			onCancel,
			selectedInvoiceState,
			isModalVisible,
			isComponentModalActive,
			getExchangeRate,
			addTransaction,
			isConfirmButtonDisabled,
			//todo delete later
		}
	},
}
</script>

<style scoped>
.header-tag {
	font-weight: bold;
}
.color-orange {
	color: orange;
}
.color-blue {
	color: blue;
}
.color-red {
	color: red;
}
.color-green {
	color: green;
}
</style>
