<template>
	<LayoutWide>
		<sub-navbar page="Transaction" />
		<div>
			<HeroBox :title="title" :subtitle="subtitle" :color="color" />
		</div>
		<div class="table-color">
			<div style="font-weight: 900; margin-bottom: 30px">
				Incoming Transactions
			</div>
			<div style="width: 500px">
				<b-field style="color: white">
					<b-select
						placeholder="Select a collection method"
						expanded
						v-model="collectionType"
					>
						<option value="collect bank">
							Bank collect with invoice number
						</option>
						<option value="collect cash">
							Cash collect with invoice number
						</option>
						<option value="collect bank in advance">
							Bank collect without invoice number
						</option>
						<option value="collect bank reminder fee">
							Bank collect reminder fee
						</option>
						<option value="get payback via collect bank">
							Get payback via bank
						</option>
						<option value="collect cash in advance">
							Cash collect without invoice number
						</option>
						<option value="collect cash reminder fee">
							Cash collect reminder fee
						</option>
						<option value="get payback collect cash">Get payback cash</option>
						<option value="set off credit invoice">
							Setoff credit invoice
						</option>
						<option value="set off advanced payment">
							Setoff advanced payment
						</option>
					</b-select>
				</b-field>
				<b-field class="margin-top-30">
					<b-datepicker
						v-model="collectionDate"
						:show-week-number="showWeekNumber"
						:locale="locale"
						placeholder="Click to select..."
						icon="calendar-today"
						trap-focus
						:first-day-of-week="firstDayOfTheWeek"
						:max-date="today"
					>
					</b-datepicker>
				</b-field>
			</div>
		</div>
		<div></div>
		<div v-if="isModalVisible">
			<VPopup :closePopup="onClosePopup">
				<div slot="header">
					Add New Payment - Fill the form to enter new payment details
				</div>
				<div slot="body"><AddIncomingTransactionFormFrame /></div>
				<div slot="footer"></div>
			</VPopup>
		</div>
		<div>
			<div
				v-if="!transactionFormState.isSearchUnpaidInvoicesShown"
				style="margin-top: 30px; color: red; font-weight: 900"
			>
				Loading...
			</div>
			<div v-if="showSearchbox === 'invoice'">
				<SearchUnpaidInvoices
					key="transactionFormState.keySearch"
					v-if="!!transactionFormState.isSearchUnpaidInvoicesShown"
				/>
			</div>
			<div v-if="showSearchbox === 'customer'">
				<SearchCustomerForIncomingTransaction
					key="transactionFormState.keySearch"
				/>
			</div>
		</div>
	</LayoutWide>
</template>
<script>
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
import SearchCustomerForIncomingTransaction from './components/SearchCustomerForIncomingTransaction.vue'
import SearchUnpaidInvoices from './components/SearchUnpaidInvoices.vue'
import AddIncomingTransactionFormFrame from './components/AddIncomingTransactionFormFrame.vue'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import useTransactionsNewState from './store/useTransactionsNewState'
import VPopup from '@/_srcv2/pages/admin/edit-stock-prices/sub-components/VPopup.vue'
import { ref, watchEffect, watch, onMounted } from '@vue/composition-api'
import useIncomingTransactionFunctions from './scripts/useIncomingTransactionFunctions'

export default {
	name: 'IncomingTransactions',
	components: {
		HeroBox,
		AddIncomingTransactionFormFrame,
		SearchCustomerForIncomingTransaction,
		SearchUnpaidInvoices,
		SubNavbar,
		VPopup,
	},
	setup() {
		const {
			showSearchbox,
			isModalVisible,
			collectionType,
			selectedInvoiceState,
			collectionDate,
			collectionDateFormatted,
			transactionFormState,
		} = useTransactionsNewState()
		const today = ref(new Date())
		collectionDate.value = today.value
		console.log('====> today', today.value)

		watch(collectionDate, (newValue) => {
			if (!collectionDate) return
			collectionDateFormatted.value = formatDateToYYYYMMDD(newValue)
			console.log(
				'====> watch collectionDateFormatted',
				collectionDateFormatted.value,
			)
		})
		const showWeekNumber = ref(false)
		const firstDayOfTheWeek = ref(1)
		const locale = 'sv-SE'

		const title = ref('Add Incoming Transactions')
		const subtitle = ref(
			'You can add incoming transactions and setoff credit invoice or advanced payment',
		)
		const color = ref('is-info')

		const onClosePopup = () => {
			isModalVisible.value = false
		}
		const { getVariables, formatDateToYYYYMMDD } =
			useIncomingTransactionFunctions()
		selectedInvoiceState.transactionType = 'collect bank'
		onMounted(() => {
			console.log('IncomingTransactions onMounted')
		})
		watchEffect(() => {
			console.log('collectionType', collectionType.value)
			switch (collectionType.value) {
				case 'collect bank':
					showSearchbox.value = 'invoice'
					break
				case 'collect cash':
					showSearchbox.value = 'invoice'
					break
				case 'set off advanced payment':
					showSearchbox.value = 'invoice'
					break
				case 'set off credit invoice':
					showSearchbox.value = 'invoice'
					break
				case 'collect bank in advance':
					showSearchbox.value = 'customer'
					break
				case 'collect bank reminder fee':
					showSearchbox.value = 'customer'
					break
				case 'get payback via collect bank':
					showSearchbox.value = 'customer'
					break
				case 'collect cash in advance':
					showSearchbox.value = 'customer'
					break
				case 'collect cash reminder fee':
					showSearchbox.value = 'customer'
					break
				case 'get payback collect cash':
					showSearchbox.value = 'customer'
					break
				default:
					showSearchbox.value = 'invoice'
			}
		})
		return {
			title,
			subtitle,
			color,
			isModalVisible,
			onClosePopup,
			getVariables,
			selectedInvoiceState,
			showSearchbox,
			collectionType,
			today,
			firstDayOfTheWeek,
			showWeekNumber,
			locale,
			collectionDate,
			transactionFormState,
		}
	},
}
</script>
