<template>
	<div v-if="selectedInvoiceState.showAddIncomingTransactionFormFrame">
		<div v-if="showSearchbox === 'invoice'">
			<div style="margin-top: 20px; font-weight: 900; font-size: large">
				Invoice Number: {{ selectedInvoiceState.invoiceNumber }}
			</div>
			<div style="font-weight: 900; font-size: large">
				Customer: {{ selectedInvoiceState.company }}
			</div>
			<div style="font-weight: 900; font-size: large">
				Currency: {{ selectedInvoiceState.invoiceCurrency }}
			</div>

			<div
				v-if="selectedInvoiceState.invoiceCurrency !== 'kr.'"
				style="font-weight: 900; font-size: large"
			>
				Invoice Exchange Rate: 1 kr. =
				{{ selectedInvoiceState.invoiceExcRate }}
				{{ selectedInvoiceState.invoiceCurrency }} -
				<span class="text-purple">
					(1
					{{ selectedInvoiceState.invoiceCurrency }} =
					{{ 1 / selectedInvoiceState.invoiceExcRate }} kr.)
				</span>
			</div>
			<div style="font-weight: 900; font-size: large">
				Transaction Type: {{ modelTransaction.transactionType }}
			</div>
			<div style="font-weight: 900; font-size: large">
				Invoice Type: {{ modelTransaction.invoiceType }}
			</div>
			<div style="font-weight: 900; font-size: large">
				Report Type: {{ modelTransaction.reportType }}
			</div>
			<div style="display: flex">
				<section
					style="
						padding-top: 20px;
						padding-bottom: 20px;
						padding-right: 20px;
						margin-right: 60px;
					"
					v-if="selectedInvoiceState.invoiceCurrency !== 'kr.'"
				>
					<span class="header-tag"
						>Invoice Total:
						<span class="color-blue"
							>{{
								numeral(selectedInvoiceState.invoiceTotal / 100).format(
									'0,0.00',
								)
							}}
							kr.</span
						>
					</span>
					<br />
					<span class="header-tag"
						>Payment Total:
						<span class="color-green"
							>{{
								numeral(selectedInvoiceState.paymentTotal / 100).format(
									'0,0.00',
								)
							}}
							kr.</span
						> </span
					><br />
					<span class="header-tag"
						>Invoice Balance:
						<span class="color-red"
							>{{
								numeral(selectedInvoiceState.balanceTotal / 100).format(
									'0,0.00',
								)
							}}
							kr.</span
						></span
					>
				</section>
				<section
					style="padding-top: 20px; padding-bottom: 20px; padding-right: 20px"
				>
					<span class="header-tag"
						>Invoice Total Exchange:
						<span class="color-blue">
							<span>
								{{
									numeral(
										selectedInvoiceState.invoiceTotalInExchange / 100,
									).format('0,0.00')
								}}
								{{ selectedInvoiceState.invoiceCurrency }}
							</span>
						</span>
					</span>
					<br />
					<span class="header-tag"
						>Payment Total Exchange:
						<span class="color-green">
							{{
								numeral(
									selectedInvoiceState.paymentTotalInExchange / 100,
								).format('0,0.00')
							}}
							{{ selectedInvoiceState.invoiceCurrency }}
						</span>
					</span>
					<br />
					<span class="header-tag"
						>Invoice Balance Exchange:
						<span class="color-red">
							{{
								numeral(
									selectedInvoiceState.balanceTotalInExchange / 100,
								).format('0,0.00')
							}}
							{{ selectedInvoiceState.invoiceCurrency }}
						</span>
					</span>
				</section>
			</div>
		</div>
		<div v-if="showSearchbox === 'customer'">
			<div style="font-weight: 900; font-size: large">
				Customer: {{ selectedInvoiceState.company }}
			</div>
			<div style="font-weight: 900; font-size: large">
				Transaction Type: {{ modelTransaction.transactionType }}
			</div>
			<div style="font-weight: 900; font-size: large">
				Payment Type: {{ modelTransaction.paymentType }}
			</div>
			<div style="font-weight: 900; font-size: large">
				Report Type: {{ modelTransaction.reportType }}
			</div>
		</div>
		<div>
			<IncomingTransactionForm />
		</div>
	</div>
</template>

<script>
import IncomingTransactionForm from './IncomingTransactionForm.vue'
import useTransactionsNewState from '../store/useTransactionsNewState'
import numeral from 'numeral'

export default {
	name: 'AddIncomingTransactionFormFrame',
	components: {
		IncomingTransactionForm,
	},
	setup() {
		const { selectedInvoiceState, modelTransaction, showSearchbox } =
			useTransactionsNewState()
		numeral.defaultFormat('0,0.00')
		return {
			selectedInvoiceState,
			modelTransaction,
			numeral,
			showSearchbox,
		}
	},
}
</script>

<style scoped>
.header-tag {
	font-weight: bold;
}
.color-orange {
	color: orange;
}
.color-blue {
	color: blue;
}
.color-red {
	color: red;
}
.color-green {
	color: green;
}
</style>
