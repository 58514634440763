<!-- @format -->

<template>
	<div>
		<div v-if="loading" style="font-weight: 900; color: red; margin-top: 60px">
			...Loading
		</div>
		<div v-if="!loading" class="table-color" style="margin-top: -20px">
			<div style="font-weight: 900">
				All unpaid invoices<br />
				<span class="text-white">Between the dates:</span> &nbsp;
				{{ beginningDate }} &nbsp; and &nbsp;
				{{ endingDate }}
				<div>
					<b-button
						class="width-250"
						style="
							background-color: rgb(52, 136, 206);
							color: white;
							margin-left: 0px;
							margin-top: 30px;
							margin-bottom: 30px;
						"
						@click="toggleDays"
					>
						{{ buttonTag }}
					</b-button>
					<b-button
						class="width-250"
						style="
							background-color: rgb(61, 196, 134);
							color: white;
							margin-left: 30px;
							margin-top: 30px;
							margin-bottom: 30px;
						"
						@click="setInitialValues"
					>
						Refresh the table
					</b-button>
				</div>
			</div>
			<div vif="refreshTableValues">
				<vue-good-table
					id="dispatches"
					:columns="columns"
					:rows="rows"
					styleClass="vgt-table striped bordered"
					theme="black-rhino"
					max-height="700px"
					:fixed-header="true"
					@on-row-dblclick="onRowDoubleClick"
					@on-cell-click="onCellClick"
					:search-options="{
						enabled: true,
					}"
					:sort-options="sortOptions"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: 10,
						position: 'bottom',
						perPageDropdown: [5, 10, 15, 20],
						dropdownAllowAll: true,
						setCurrentPage: 1,
						jumpFirstOrLast: true,
						firstLabel: 'First',
						lastLabel: 'Last',
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page',
						allLabel: 'All',
					}"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field === 'select'">
							<b-button
								class="is-small is-success"
								:disabled="isSelectDisabled"
							>
								Select
							</b-button>
						</span>
						<span v-else-if="props.column.field === 'invoiceTotalExchange'">
							<p class="text-bold text-purple text-align-right">
								{{
									numeral(props.row.invoiceTotalExchange / 100).format('0,0.00')
								}}
							</p>
						</span>
						<span v-else-if="props.column.field === 'paymentExchange'">
							<p class="text-bold text-green text-align-right">
								{{ numeral(props.row.paymentExchange / 100).format('0,0.00') }}
							</p>
						</span>
						<span v-else-if="props.column.field === 'balanceExchange'">
							<p class="text-bold text-red text-align-right">
								{{ numeral(props.row.balanceExchange / 100).format('0,0.00') }}
							</p>
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
			</div>

			<download-csv :data="rows">
				<div class="margin-bottom-30 margin-top-30">
					<!-- <b-button class="is-success"> Download the List </b-button> -->
					<b-button
						class="width-250"
						style="background-color: rgb(61, 196, 134); color: white"
					>
						Download the List
					</b-button>
				</div>
			</download-csv>
		</div>
	</div>
</template>

<script>
import Store from '@/store'
import { onMounted, reactive, ref, computed, watch } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import SearchUnpaidInvoicesQuery from '../graphql/SearchUnpaidInvoicesQuery.graphql'
import useTransactionsNewState from '../store/useTransactionsNewState'
import useIncomingTransactionFunctions from '../scripts/useIncomingTransactionFunctions'
import numeral from 'numeral'
import alarmsound from '@/assets/sounds/alarm.mp3'
import dateformat from 'dateformat'

export default {
	name: 'SearchUnpaidInvoices',
	setup() {
		const buttonType = ref('is-success')
		// ! -----------------------------------------------------------------
		const today = ref(new Date())
		const days = ref(365)
		const buttonTag = ref('Get without time limited')
		const promiseDays = (condition) => {
			return new Promise((resolve, reject) => {
				if (condition) {
					console.log('Condition is ', condition)
					if (days.value === 365) {
						days.value = 1500
					} else {
						days.value = 365
					}
					if (buttonTag.value === 'Get without time limited') {
						buttonTag.value = 'Get last year'
					} else {
						buttonTag.value = 'Get without time limited'
					}
					if (buttonType.value === 'is-success') {
						buttonType.value = 'is-info'
					} else {
						buttonType.value = 'is-success'
					}
					resolve(true)
				} else {
					const error = new Error('Condition is false')
					reject(error)
				}
			}).catch((err) => console.log('Error: ', err))
		}

		const toggleDays = () => {
			console.log('toggle is fired')
			promiseDays(true).then(() => setInitialValues())
		}
		const beginningDate = computed(() => {
			const bd = new Date().setTime(
				today.value - days.value * 24 * 60 * 60 * 1000,
			)
			console.log('bd', bd)
			console.log('myDate', dateformat(bd, 'yyyy-mm-dd'))
			return dateformat(bd, 'yyyy-mm-dd')
		})
		const endingDate = computed(() => {
			return dateformat(today.value, 'yyyy-mm-dd')
		})
		// ! ----------------------------------------------------------------------------------------
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'invoiceNum', type: 'asc' },
		}
		// Get data from database
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch, loading } = useQuery(
			SearchUnpaidInvoicesQuery,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
				transaction_type: 'sales invoice ',
				bd: beginningDate.value,
				ed: endingDate.value,
			}),
			options,
		)
		const {
			rows,
			invoiceToPay,
			invoiceToPayExchange,
			invoiceBalance,
			invoiceBalanceExchange,
			invoicePayment,
			invoicePaymentExchange,
			selectedInvoiceState,
			isModalVisible,
			collectionType,
			keyRefreshSearchUnpaidInvoices,
		} = useTransactionsNewState()

		const getInvoiceStatus = (item) => {
			if (item.is_sent_to_kronofogden) {
				return 'KF'
			} else if (item.is_sent_in_the_bailiffs) {
				return 'IK'
			} else if (item.is_invoice_reminded) {
				return 'R'
			} else {
				return ''
			}
		}
		const setInitialValues = () => {
			refetch().then((result) => {
				console.log('result', result)
				rows.value = result.data.document_transactions.map((item) => {
					return {
						invoiceNum: item.invoice_number,
						customerId: item.customer.customer_id,
						customer: `${item.customer.customer_id} - ${item.customer.customer_title} - ${item.customer.customer_nickname}`,
						invoiceDate: item.invoice_date,
						invoiceDueDate: item.invoice_due_date,
						reminderDueDate:
							item?.reminder_lines?.reminder_header?.reminder_due_date ?? '',
						invoiceTotalExchange: invoiceToPayExchange(item),
						paymentExchange: invoicePaymentExchange(item),
						balanceExchange: invoiceBalanceExchange(item),
						// ? --------------------------------------------------------------------------
						invoiceTotal: invoiceToPay(item),
						payment: invoicePayment(item),
						balance: invoiceBalance(item),
						// ? --------------------------------------------------------------------------
						status: getInvoiceStatus(item),
						ourCompany: item.our_company,
						invoiceType: item.invoice_type,
						exchangeRate: item.invoice_exchange_rate,
						exchangeUnit: item.invoice_exchange_unit,
					}
				})
			})
		}
		const refreshTableValues = ref(false)
		watch(keyRefreshSearchUnpaidInvoices, (newValue) => {
			console.warn('keyRefreshSearchUnpaidInvoices', newValue)
		})
		onMounted(() => {
			console.log('SearchUnpaidInvoices onMounted')
			setInitialValues()
			refreshTableValues.value = true
		})
		const columns = [
			{
				label: 'Select',
				field: 'select',
				width: '95px',
				sortable: false,
			},
			{
				label: 'Inv.No.',
				field: 'invoiceNum',
				width: '115px',
			},
			{
				label: 'Customer',
				field: 'customer',
				width: '265px',
			},
			{
				label: 'Invoice Due Date',
				field: 'invoiceDueDate',
				width: '115px',
			},
			{
				label: 'Reminder Due Date',
				field: 'reminderDueDate',
				width: '115px',
			},
			{
				label: 'Invoice Total',
				field: 'invoiceTotalExchange',
				width: '110px',
			},
			{
				label: 'Payment',
				field: 'paymentExchange',
				width: '110px',
			},
			{
				label: 'Balance',
				field: 'balanceExchange',
				width: '110px',
			},

			{
				label: 'E',
				field: 'exchangeUnit',
				width: '30px',
			},
			{
				label: 'Sta',
				field: 'status',
				width: '60px',
			},
		]
		const { getVariables } = useIncomingTransactionFunctions()

		const setTransactionFormValues = (params) => {
			// ? --------------------------------------------------------------------------
			selectedInvoiceState.invoiceOurCompany = params.row.ourCompany
			selectedInvoiceState.customerId = params.row.customerId
			selectedInvoiceState.company = params.row.customer
			selectedInvoiceState.invoiceNumber = params.row.invoiceNum
			// selectedInvoiceState.transactionType = 'sales invoice '
			selectedInvoiceState.invoiceType = params.row.invoiceType
			selectedInvoiceState.isCredit = params.row.invoiceTotal < 0
			selectedInvoiceState.invoiceExcRate = params.row.exchangeRate
			selectedInvoiceState.invoiceCurrency = params.row.exchangeUnit
			// ! --------------------------------------------------------------
			selectedInvoiceState.invoiceTotal = params.row.invoiceTotal
			selectedInvoiceState.paymentTotal = params.row.payment
			selectedInvoiceState.balanceTotal = params.row.balance
			// ! --------------------------------------------------------------
			selectedInvoiceState.invoiceTotalInExchange =
				params.row.invoiceTotalExchange
			selectedInvoiceState.paymentTotalInExchange = params.row.paymentExchange
			selectedInvoiceState.balanceTotalInExchange = params.row.balanceExchange
			// ! ---------------------------------------------------------------------
			selectedInvoiceState.balanceTotalDb = params.row.balance / 100
			selectedInvoiceState.balanceTotalInExchangeDb =
				params.row.balanceExchange / 100
			getVariables(collectionType.value)
			// todo Refresh Transaction Form
			selectedInvoiceState.showAddIncomingTransactionFormFrame = false
			setTimeout(() => {
				selectedInvoiceState.showAddIncomingTransactionFormFrame = true
			}, 750)
			Store.dispatch(
				'setKeyTransactionForm',
				Store.getters.getKeyTransactionForm + 1,
			)
		}
		const condition = true
		const p1 = () => {
			return new Promise((resolve, reject) => {
				if (condition) {
					playSound(alarmsound)
					resolve(true)
				} else {
					const error = new Error('Condition is false')
					reject(error)
				}
			}).catch((err) => console.log('Error: ', err))
		}

		const playSound = (sound) => {
			if (sound) {
				var audio = new Audio(sound)
				audio.play().then(() => {
					setTimeout(() => {
						alert('Selected Invoice is sent to inkasso')
					}, 200)
				})
			}
		}
		const isSelectDisabled = ref(false)
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log('select is clicked')
				isSelectDisabled.value = true
				setTimeout(() => {
					isSelectDisabled.value = false
				}, 1000)
				if (params.row.status === 'IK') {
					console.log('is inkasso')
					p1().then((payload) => {
						if (payload) {
							setTransactionFormValues(params)
							selectedInvoiceState.showAddIncomingTransactionFormFrame = false
							setTimeout(() => {
								isModalVisible.value = true
								selectedInvoiceState.showAddIncomingTransactionFormFrame = true
							}, 750)
							return true
						}
					})
				} else {
					setTransactionFormValues(params)
					selectedInvoiceState.showAddIncomingTransactionFormFrame = false
					setTimeout(() => {
						isModalVisible.value = true
						selectedInvoiceState.showAddIncomingTransactionFormFrame = true
					}, 750)
				}
			} else {
				console.log('wait')
			}
		}

		const onRowDoubleClick = (params) => {
			if (params.column.field === 'select') {
				console.log('select is clicked')
				isSelectDisabled.value = true
				setTimeout(() => {
					isSelectDisabled.value = false
				}, 1000)
				if (params.row.status === 'IK') {
					console.log('is inkasso')
					p1().then((payload) => {
						if (payload) {
							setTransactionFormValues(params)
							selectedInvoiceState.showAddIncomingTransactionFormFrame = false
							setTimeout(() => {
								isModalVisible.value = true
								selectedInvoiceState.showAddIncomingTransactionFormFrame = true
							}, 750)
							return true
						}
					})
				} else {
					setTransactionFormValues(params)
					selectedInvoiceState.showAddIncomingTransactionFormFrame = false
					setTimeout(() => {
						isModalVisible.value = true
						selectedInvoiceState.showAddIncomingTransactionFormFrame = true
					}, 750)
				}
			} else {
				console.log('wait')
			}
		}
		//-----------------------------------------------
		return {
			numeral,
			sortOptions,
			columns,
			rows,
			onCellClick,
			onRowDoubleClick,
			isSelectDisabled,
			loading,
			beginningDate,
			endingDate,
			toggleDays,
			buttonTag,
			buttonType,
			setInitialValues,
		}
	},
}
</script>

<style scoped></style>
