<template>
	<div v-if="!loading" class="table-color" style="margin-top: -20px">
		<b-button
			class="margin-top-10 margin-bottom-10 width-250"
			style="background-color: rgb(61, 196, 134); color: white"
			@click="refetchData"
		>
			Refresh the table
		</b-button>
		<ve-table
			:rows="rows"
			:columns="columns"
			:onCellClick="onCellClick"
			:onRowDoubleClick="onRowDoubleClick"
			:sort-options="sortOptions"
		>
		</ve-table>
	</div>
</template>

<script>
import Store from '@/store'
import { useQuery } from '@vue/apollo-composable'
import { onMounted, reactive, ref } from '@vue/composition-api'
import GetActiveCustomersForIncomingTransactionQuery from '../graphql/GetActiveCustomersForIncomingTransactionQuery.graphql'
import useTransactionsNewState from '../store/useTransactionsNewState'
import useIncomingTransactionFunctions from '../scripts/useIncomingTransactionFunctions'

export default {
	name: 'SearchCustomerForIncomingTransaction',
	setup() {
		const { getVariables } = useIncomingTransactionFunctions()
		const columns = ref([
			{
				label: 'Select',
				field: 'select',
				width: '50px',
				sortable: false,
			},
			{
				label: 'Customer ID',
				field: 'customer_id',
				width: '135px',
				thClass: 'center-it',
			},
			{
				label: 'Customer Title',
				field: 'customer_title',
				thClass: 'center-it',
			},
			{
				label: 'Customer Nickname',
				field: 'customer_nickname',
				thClass: 'center-it',
			},
			{
				label: 'Org.Number',
				field: 'customer_org_num',
				width: '135px',
				thClass: 'center-it',
			},
		])
		const rows = ref([])
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'customer_title', type: 'asc' },
		}
		// * ---------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'cache-first',
		})
		const { refetch: refetchCustomers, loading } = useQuery(
			GetActiveCustomersForIncomingTransactionQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		const refetchData = () => {
			refetchCustomers().then((result) => {
				rows.value = result.data.customers
				console.log('rows.value', rows.value)
			})
		}
		onMounted(() => {
			refetchData()
		})
		// * -----------------------------------------------------------------
		const {
			selectedInvoiceState,
			isModalVisible,
			collectionType,
			// showSearchbox,
		} = useTransactionsNewState()
		const setTransactionFormValues = (params) => {
			return new Promise((resolve, reject) => {
				let item = params.row
				if (item !== undefined) {
					selectedInvoiceState.invoiceOurCompany =
						Store.getters.getUserCurrentCompany
					selectedInvoiceState.customerId = item.customer_id
					selectedInvoiceState.company =
						item.customer_nickname +
						' - ' +
						item.customer_title +
						' - ' +
						item.customer_org_num
					selectedInvoiceState.invoiceNumber = 'Utan Faktura'
					// selectedInvoiceState.transactionType = ''
					// selectedInvoiceState.invoiceType = ''
					// selectedInvoiceState.isInExchange = false
					selectedInvoiceState.invoiceCurrency = 'kr.'
					// *------------------------------------------------------------
					selectedInvoiceState.invoiceTotal = ''
					selectedInvoiceState.paymentTotal = ''
					selectedInvoiceState.balanceTotal = 100000000000
					// *------------------------------------------------------------
					selectedInvoiceState.invoiceTotalInExchange = ''
					selectedInvoiceState.paymentTotalInExchange = ''
					selectedInvoiceState.balanceTotalInExchange = 100000000000
					// *------------------------------------------------------------
					getVariables(collectionType.value)
					// Refresh Transaction Form
					Store.dispatch(
						'setKeyTransactionForm',
						Store.getters.getKeyTransactionForm + 1,
					)
					resolve(item)
				} else {
					reject('Something is wrong')
				}
			})
		}
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				setTransactionFormValues(params)
				selectedInvoiceState.showAddIncomingTransactionFormFrame = false
				setTimeout(() => {
					isModalVisible.value = true
					selectedInvoiceState.showAddIncomingTransactionFormFrame = true
				}, 750)
			}
		}
		const onRowDoubleClick = (params) => {
			setTransactionFormValues(params)
			selectedInvoiceState.showAddIncomingTransactionFormFrame = false
			setTimeout(() => {
				isModalVisible.value = true
				selectedInvoiceState.showAddIncomingTransactionFormFrame = true
			}, 750)
		}
		return {
			columns,
			rows,
			onCellClick,
			onRowDoubleClick,
			sortOptions,
			loading,
			refetchData,
		}
	},
}
</script>

<style scoped></style>
